import router from "next/router";
import { useEffect } from "react";
import moment from "jalali-moment";
import { Subscription } from "rxjs";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { serialize, parse } from "cookie";
import { AxiosRequestConfig } from "axios";
import { GetServerSidePropsContext as Context,
  GetServerSidePropsResult as Result } from "next";

import Res from "types/response";
import api, { ssr } from "helpers/api";
import { AuthTokens } from "types/user";
import user$, { setUser } from "store/user";

export const access: string = 'access_token';
export const refresh: string = 'refresh_token';

export function getTokens(req?: AxiosRequestConfig): Partial<AuthTokens> {
  // return { access: Cookies.get(access), refresh: Cookies.get(refresh) };
  const cookies = parse(ssr ? <string>req?.headers?.cookie || '' : document.cookie);
  return { access: cookies[access], refresh: cookies[refresh] };
}

export function setTokens(tokens: AuthTokens): AuthTokens {
  Cookies.set(access, tokens.access);
  Cookies.set(refresh, tokens.refresh);
  return tokens;
}

// TODO: Refresh and set token on server side
export function refreshTokens(): Promise<AuthTokens> {
  return new Promise((resolve, reject) => api.req({
    url: 'v1/auth/refresh', method: 'post',
    data: { refresh: getTokens().refresh }
  }).then(({ data }) => { resolve(setTokens(data)); })
    .catch((error) => { clearAuth(); reject(error); }));
}

export function clearAuth(): void {
  Cookies.remove(access);
  Cookies.remove(refresh);
  setUser(null);
  // By setting the user as null if the page needs to authenticate,
  // the checkAuthClient method will redirect user to the login page.
}

export function logout(): Promise<void> {
  return api.set('v1/auth/logout', 'post', {
    body: { refresh: getTokens().refresh }
  }).then(() => clearAuth());
}

// TODO: We can call the profile API with current token -
// - to make sure the token is not expired.
export function checkAuth(context: Context): Result<any> | void {
  const token = parse(context.req.headers.cookie || '')[refresh];
  const exp = token ? jwtDecode<{ exp: number; }>(token).exp : 0;
  if (exp && exp * 1e3 > Date.now()) { return; }
  // if (context.req.headers.cookie?.includes(refresh)) { return; }
  const url = context.resolvedUrl.split('?')[0];
  context.res.setHeader('Set-Cookie', serialize(redirectKey, url,
    { expires: moment().add(5, 'minutes').toDate(), path: '/' }));
  return { redirect: { destination: '/auth/login', permanent: true } };
}

export function checkAuthClient() {
  const observer: Subscription = user$.subscribe((user) => {
    if (user) { return; }
    const url = router.asPath;
    Cookies.set(redirectKey, url, { expires: (1 / (24 * 60)) * 5 });
    router.replace('/auth/login');
  });
  return () => observer.unsubscribe();
}

export function useCheckAuth() {
  useEffect(() => checkAuthClient(), []);
};

export const redirectKey: string = 'next';
export function redirectBack(): void {
  router.push(Cookies.get(redirectKey) || '/');
  Cookies.remove(redirectKey);
}
