import Head from "next/head";
import { useEffect } from "react";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import { appWithTranslation } from "next-i18next";

import "styles/globals.scss";
import "styles/rtl.scss";
import { checkUser, getUser } from "store/user";
import { ErrorBoundary } from "@sentry/nextjs";
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    useEffect(() => {
        if (router.isReady) checkUser();
        async function avoidAuth(url: string): Promise<void> {
            if (url.includes("/auth/") && (await getUser()))
                router.replace("/");
        }
        avoidAuth(router.asPath);
        router.events.on("routeChangeStart", avoidAuth);
        // router.locale == 'fa' && document.documentElement.setAttribute('dir', 'rtl');
        return () => router.events.off("routeChangeStart", avoidAuth);
    }, [router]);

    return (
        <>
            <Head>
                <title>Ticlix</title>

                {/* Google tag (gtag.js) */}
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=G-D9VL52FQTW"
                ></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-D9VL52FQTW');`}
                </script>
                {/* Google tag (gtag.js) */}

                {/* microsoft clarity */}
                <script type="text/javascript">
                    {`(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "ml0f6k3cyy");
                `}
                </script>
                {/* microsoft clarity */}
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#dc5136"
                />
                <meta name="msapplication-TileColor" content="#dc5136" />
                <meta name="theme-color" content="#ffffff" />

                <meta
                    name="title"
                    content="Ticlix - online sales authority for concert tickets of famous singers in the music industry"
                />
                <meta
                    name="description"
                    content="TICLIX is a ticketing platform with a world class operational structure to facilitate tickets sales for different events across the world. We adhere to international standards of operation and are proud to have gained in a short time the recognition within the industry. With over 20 years of experience in IT and web development, we do believe that TICLIX is a very unique platform for ticketing consisting very flexible features and options that will provide great level of services to our clients and customers. The main model and the bases of our framework is the ease of ticket purchase with only few clicks by users where our clients take pride in their experience with us. For our existing customers and the future ones that will join, we are on a constant move for acquiring new technology, expertise, knowledge and providing cutting edge communication channels for ease of services and sales. With TICLIX, you can simply make your time great again!"
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ticlix.com/" />
                <meta
                    property="og:title"
                    content="Ticlix - online sales authority for concert tickets of famous singers in the music industry"
                />
                <meta
                    property="og:description"
                    content="TICLIX is a ticketing platform with a world class operational structure to facilitate tickets sales for different events across the world. We adhere to international standards of operation and are proud to have gained in a short time the recognition within the industry. With over 20 years of experience in IT and web development, we do believe that TICLIX is a very unique platform for ticketing consisting very flexible features and options that will provide great level of services to our clients and customers. The main model and the bases of our framework is the ease of ticket purchase with only few clicks by users where our clients take pride in their experience with us. For our existing customers and the future ones that will join, we are on a constant move for acquiring new technology, expertise, knowledge and providing cutting edge communication channels for ease of services and sales. With TICLIX, you can simply make your time great again!"
                />
                {/* <meta property="og:image" content="https://ticlix.com/static/images/ticlix-landing.jpg" /> */}

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ticlix.com/" />
                <meta
                    property="twitter:title"
                    content="Ticlix - online sales authority for concert tickets of famous singers in the music industry"
                />
                <meta
                    property="twitter:description"
                    content="TICLIX is a ticketing platform with a world class operational structure to facilitate tickets sales for different events across the world. We adhere to international standards of operation and are proud to have gained in a short time the recognition within the industry. With over 20 years of experience in IT and web development, we do believe that TICLIX is a very unique platform for ticketing consisting very flexible features and options that will provide great level of services to our clients and customers. The main model and the bases of our framework is the ease of ticket purchase with only few clicks by users where our clients take pride in their experience with us. For our existing customers and the future ones that will join, we are on a constant move for acquiring new technology, expertise, knowledge and providing cutting edge communication channels for ease of services and sales. With TICLIX, you can simply make your time great again!"
                />
                {/* <meta property="twitter:image" content="https://ticlix.com/static/images/ticlix-landing.jpg"></meta> */}
            </Head>
            <ErrorBoundary>
                <Component {...pageProps} />
            </ErrorBoundary>
            <ToastContainer
                position="bottom-center"
                rtl={router.locale == "fa"}
            />
        </>
    );
}

export default appWithTranslation(MyApp);
