import wNumb from "wnumb";
import { RefObject } from "react";
import { BehaviorSubject, firstValueFrom } from "rxjs";
export const Price = wNumb({ suffix: " ", decimals: 0, thousand: "," });

import api from "helpers/api";
import { User } from "types/user";
import { Invoice } from "types/invoice";
import { Cart, CartItem } from "types/cart";
import { downloadBlob } from "helpers/utils";
import { ConcertTime } from "types/concert-time";
import { Ticket, TicketFilters, TicketStatus } from "types/ticket";
import Res, { Page, Currency } from "types/response";

const emptyCart: Cart = { items: [], total_items: 0, total_price: 0 };
const cart$ = new BehaviorSubject<Cart>(emptyCart);
export default cart$.asObservable();

export async function fetchCart(): Promise<Cart> {
    const { data }: Res<Currency<Cart>> = await api.set("v1/carts");
    return setCart(data);
}

export function setCart(data?: Currency<Cart>): Cart {
    const cart = data?.results[0] || emptyCart;
    for (const item of cart.items as CartItem[]) item.seats.reverse();
    cart.total_items = cart.items.reduce((c, i) => c + i.seats.length, 0);
    cart$.next(cart);
    return cart;
}

export function getCart(): Promise<Cart> {
    return firstValueFrom(cart$);
}

export async function clearCart(): Promise<Cart> {
    await api.set("v1/carts", "delete");
    return setCart();
}

export async function addToCart(
    concert: string,
    time: string,
    seat: string,
    currency: string
): Promise<Cart> {
    const { data }: Res<Currency<Cart>> = await api.set("v1/carts", "post", {
        body: { time_id: time, seat, currency },
    });
    return setCart(data);
}

export async function removeFromCart(
    concert: string,
    time: string,
    seat?: string
): Promise<Cart> {
    const { data }: Res<Currency<Cart>> = await api.set(
        ["v1/carts", time, seat].join("/"),
        "delete"
    );
    // TODO: Update cart on catch
    return setCart(data);
}

export async function finalizeCart(
    ref?: RefObject<HTMLElement>
): Promise<void> {
    const id = "post:v1/carts/finalize/pay"; // To avoid multiple requests
    const invoice_id = await api
        .set("v1/carts/finalize", "post", { id, ref })
        .then(({ data }: Res<Currency<Invoice>>) => data.results[0].id);
    const { data }: Res<{ pay_url: string }> = await api.set(
        "v1/invoices/pay",
        "post",
        { body: { invoice_id, payment_method: "stripe" }, id, ref }
    );
    location.replace(data.pay_url);
}

export async function adminFinalize(
    body: Partial<User>,
    ref?: RefObject<HTMLElement>
): Promise<void> {
    const { data } = await api.set("v1/admin/finalize-cart", "post", {
        body,
        ref,
    });
    // downloadBlob(blob);
}

/* ================================ Invoices =============================== */

export async function getInvoices(): Promise<Page<Invoice>> {
    const { data }: Res<Page<Invoice>> = await api.set("v1/invoices");
    return data;
}

export async function getInvoice(id: string, headers?: any): Promise<Invoice> {
    const { data }: Res<Invoice> = await api
        .set(`v1/invoices/${id}`, "get", { headers })
        .catch(() => null);
    return data;
}

/* ================================ Tickets ================================ */

export type TimeTicketsAdmin = {
    concert_time: ConcertTime;
    currency: string;
    emirate_government_barcode: string;
    id: string;
    invoice_id: string;
    purchase: string;
    purchase_date: string;
    seat: string;
    seat_group_title: string;
    status: TicketStatus;
    anonymous_user: { id: number; first_name: string; last_name: string };
};

export interface TimeTickets extends ConcertTime {
    tickets?: Ticket[];
    emirate_government_barcode: string;
}
export async function getTimesTickets(
    params?: TicketFilters
): Promise<Page<TimeTickets>> {
    const { data }: Res<Page<TimeTickets>> = await api.set(
        "v1/tickets-times",
        "get",
        { params }
    );
    return data;
}

export async function getAdminTimesTickets(
    params?: TicketFilters
): Promise<Page<TimeTicketsAdmin>> {
    const { data }: Res<Page<TimeTicketsAdmin>> = await api.set(
        "v1/admin/payment/tickets",
        "get",
        { params }
    );
    return data;
}

export async function getTickets(
    params?: TicketFilters
): Promise<Page<Ticket>> {
    const { data }: Res<Page<Ticket>> = await api.set("v1/tickets", "get", {
        params,
    });
    return data;
}

export async function getTicket(
    id: string,
    params?: TicketFilters
): Promise<Ticket> {
    const { data }: Res<Ticket> = await api.set(`v1/tickets/${id}`, "get", {
        params,
    });
    return data;
}

export async function downloadTickets(
    params?: TicketFilters,
    name?: string
): Promise<{ success: boolean; data: any }> {
    const res = await api.set("v1/tickets", "get", {
        params: { ...params },
        // options: { responseType: 'blob' },
    });
    return res;
    // downloadBlob(blob, name);
}

export async function downloadTicket(
    id: string,
    name?: string
): Promise<{ success: boolean; data: any }> {
    const res = await api.set(`v1/tickets/${id}`, "get", {
        params: {},
        // options: { responseType: "blob" },
    });
    return res;
    // downloadBlob(blob, name);
}
