import { ReplaySubject, firstValueFrom } from "rxjs";

import api from "helpers/api";
import Res from "types/response";
import { getTokens } from "store/auth";
import { User, User$ } from "types/user";
import storage from "helpers/local-storage";
import { fetchCart, setCart } from "store/cart";

const user$ = new ReplaySubject<User$>(1);
export default user$.asObservable();

export const storageKey: string = 'user';

export async function checkUser(): Promise<User$> {
  if (!getTokens().refresh) return setUser(null);
  let user = storage.get<User$>(storageKey);
  user && user$.next(user);
  user = await fetchUser();
  user && fetchCart();
  return user;
}

export function setUser(user: User$): User$ {
  // if (user && user.avatar.includes('default'))
    // user.avatar = '/static/images/default-avatar.png';
    // user.avatar = '/static/images/user-photo.png';
  user$.next(user); !user && setCart();
  return storage.set<User$>(storageKey, user);
}

export function getUser(): Promise<User$> {
  return firstValueFrom(user$);
}

export async function fetchUser(): Promise<User$> {
  const user: User$ = await api.set('v1/users/profile')
    .then(({ data }: Res<User>) => data).catch(() => null);
  return setUser(user);
}
