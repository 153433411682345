import { RefObject } from "react";
import i18n from "i18next";
import { toast } from "react-toastify";
import axios, {
    AxiosError,
    CancelTokenSource,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";

import Res from "types/response";
import { downloadBlob } from "helpers/utils";
import { getTokens, refreshTokens } from "store/auth";
import { default as Router } from "next/router";
export type Method = "get" | "post" | "put" | "patch" | "delete";

export interface Config {
    id?: string;
    force?: boolean;
    body?: any;
    params?: any;
    formData?: boolean;
    headers?: any;
    options?: any;
    ref?: RefObject<HTMLElement>;
}

export const ssr: boolean = typeof window == "undefined";
const baseURL = ssr ? process.env.API_URL : "/api/";

export const instance = axios.create({
    headers: { "Content-Type": "application/json" },
    baseURL,
    timeout: 3e4,
    paramsSerializer: httpParams,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = config.headers || {};
    const token = getTokens(config).access;
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
});

instance.interceptors.response.use(
    ({ config, headers, data }: AxiosResponse) => {
        if (config.responseType === "blob") {
            const name = headers["content-disposition"]
                ?.replace("attachment; filename=", "")
                .replace(/\"/g, "");
            name && downloadBlob(data, name);
        }
        return data;
    },
    async (error: AxiosError) => {
        const { config, request, response } = error;

        const url = config?.url || request?.responseURL;
        if (response?.status === 401 && !url.includes("/auth/")) {
            try {
                await refreshTokens();
                return axios(config);
            } catch (refreshError) {
                // Handle refresh token error if needed
            }
        }
        if (response) {
            let { data, status } = response as AxiosResponse<Res>;
            status = status >= 500 ? 500 : status;
            if ([400, 401, 403, 404, 406, 429, 500].includes(status)) {
                if (response?.data?.error?.code === 1208) {
                    toast.error(
                        "The total tickets you may buy even in different sessions is 10. And now this limit is reached."
                    );
                } else {
                    if (data.error.params) {
                        Object.keys(data.error.params).map((key) => {
                            const modKey =
                                key.charAt(0).toUpperCase() + key.slice(1);

                            const values = data.error.params
                                ? data.error.params[key]
                                : ["has unknown error."];

                            values.map((value) => {
                                const modValue =
                                    value.charAt(0).toUpperCase() +
                                    value.slice(1);
                                toast.error(`${modKey} : ${modValue}`);
                            });
                        });
                    } else {
                        toast.error(i18n?.t(`errors:${data.error.msg}`));
                        if (data.error.msg === "INVALID_TOKEN")
                            setTimeout(() => {
                                Router.replace("/auth/login");
                            }, 3500);
                    }
                }
            }
        }
    }
);

const sources: Map<string, CancelTokenSource> = new Map();

function formData(data: any = {}): FormData {
    const formData = new FormData();
    for (const key in data) formData.append(key, data[key]);
    return formData;
}

export function httpParams(params: any = {}): string {
    params = { ...params };
    for (const key in params) {
        const val = params[key];
        ((!val && val != 0) || (Array.isArray(val) && !val.length)) &&
            delete params[key];
    }
    return new URLSearchParams(params).toString();
}

function set(
    url: string,
    method: Method = "get",
    {
        id,
        force,
        ref,
        headers = {},
        params,
        body,
        formData: isForm,
        options,
    }: Config = {}
): Promise<any> {
    const source = axios.CancelToken.source();
    id = id || `${method.toLowerCase()}:${url.split("?").shift()}`;
    if (sources.has(id) && !force) {
        return Promise.reject(null);
    }
    ref?.current?.setAttribute("loading", "true");
    remove(id);
    sources.set(id, source);
    return instance({
        url,
        method,
        headers,
        params,
        data: body && isForm ? formData(body) : body,
        ...options,
        cancelToken: source.token,
    }).finally(() => {
        if (sources.get(id as string) !== source) {
            return;
        }
        sources.delete(id as string);
        ref?.current?.removeAttribute("loading");
    });
}

function remove(id: string, reason: string = ""): void {
    sources.get(id)?.cancel(reason);
    sources.delete(id);
}

const api = { req: instance.request, set, remove };
export default api;
