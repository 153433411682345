const prefix: string = 'ticlix';

function get<T>(key: string): T {
  const val: any = localStorage.getItem(`${prefix}.${key}`);
  try { return JSON.parse(val); } catch (err) { return val; }
}

function set<T>(key: string, val: T): T {
  const string = typeof val == 'object' ? JSON.stringify(val) : val;
  localStorage.setItem(`${prefix}.${key}`, string as string);
  return val;
}

function remove(key: string): void {
  localStorage.removeItem(`${prefix}.${key}`);
}

function clear(): void {
  localStorage.clear();
}

const storage = { prefix, get, set, remove, clear };
export default storage;
